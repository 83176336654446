import { useEffect } from "react";

const RefundPolicy = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <main className='max-w-7xl mx-auto px-4 md:px-8'>
                <section className="py-10">
                    <h1 style={{ fontWeight: 'bolder' }} className="text-xl md:text-2xl text-center font-black text-[#FF6500] pb-4">Cancellation & Refund Policy</h1>

                    <h2 className="text-base font-semibold mt-6">Cancellation Policy</h2>
                    <ol className="text-[13px] space-y-6 list-inside pl-4 mt-4">
                        <li className="font-semibold text-sm">Training Bookings:
                            <ul className="list-disc pl-6 font-normal text-sm">
                                <li>No cancellation allowed for training registration but users can postpone their participation in the next available training program for once.</li>
                                <li>Users can postpone their training attendance up to 15 days before the scheduled training.</li>
                                <li>Late postponement may be subject to cancellation of training registration fee.</li>
                            </ul>
                        </li>
                        <li className="font-semibold text-sm">Event Registrations:
                            <ul className="list-disc pl-6 font-normal text-sm">
                                <li>No cancellations allowed for events.</li>
                            </ul>
                        </li>
                        <li className="font-semibold text-sm">Product (Books) Purchases:
                            <ul className="list-disc pl-6 font-normal text-sm">
                                <li>Orders can be cancelled before they are shipped.</li>
                                <li>Once shipped, the standard refund policy applies.</li>
                            </ul>
                        </li>
                    </ol>

                    <h2 className="text-base font-semibold mt-6">Refund Policy</h2>
                    <ol className="text-[13px] space-y-6 list-inside pl-4 mt-4">
                        <li className="font-semibold text-sm">Training Bookings:
                            <ul className="list-disc pl-6 font-normal text-sm">
                                <li>No refunds for training bookings unless Spectransys cancels the training program.</li>
                                <li>To request a refund, users must contact our support team at <a href="mailto:admin@spectransyscore.com" className="text-blue-600 underline">admin@spectransyscore.com</a>.</li>
                                <li>Refunds are processed using the original payment method.</li>
                            </ul>
                        </li>
                        <li className="font-semibold text-sm">Product Purchases:
                            <ul className="list-disc pl-6 font-normal text-sm">
                                <li>Refunds for purchased items (books) are available within 5 days of receiving the product.</li>
                                <li>Products must be returned in their original condition for a refund.</li>
                                <li>Shipping costs for returns are the responsibility of the customer.</li>
                            </ul>
                        </li>
                        <li className="font-semibold text-sm">Conference/Event Registrations:
                            <ul className="list-disc pl-6 font-normal text-sm">
                                <li>No refunds for event registrations unless Spectransys cancels the event.</li>
                                <li>Refund requests should be submitted to <a href="mailto:admin@spectransyscore.com" className="text-blue-600 underline">admin@spectransyscore.com</a>.</li>
                            </ul>
                        </li>
                    </ol>
                    <p className="text-sm font-sans py-4"><span className="font-semibold"> Last Updated :</span> 14/02/2024</p>
                </section>
            </main>
        </>
    );
}

export default RefundPolicy;
