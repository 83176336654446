import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="py-3 bg-[#fff4eb] mt-8">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="w-full md:w-1/2 text-center md:text-left">
          © 2025 Spectransys All Rights Reserved
        </div>
        <div className="gap-5 text-right flex justify-around md:justify-between">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/refund-policy">Refund Policy</Link>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          <a href={`mailto:admin@spectransyscore.com`} >Contact</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
