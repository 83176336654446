import { useEffect } from "react";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <main className='max-w-7xl mx-auto px-4 md:px-8'>
                <section className="py-10">
                    <h1 style={{ fontWeight: 'bolder' }} className="text-xl md:text-2xl text-center font-black text-[#FF6500] pb-4">Privacy Policy </h1>

                    <p className="mt-4 text-sm">Spectransys Pvt Ltd ("we" or "us") is committed to protecting the privacy of the users of Spectransys's Application ("the Application"). This Privacy Policy outlines our practices concerning the collection, use, and disclosure of user information.</p>
                    <ol className="text-[13px] space-y-6 list-inside pl-0 mt-4">
                        <li className=" font-semibold">Information We Collect
                            <ol className="list-inside pl-0 font-normal text-base" style={{ listStyleType: 'lower-alpha' }}>
                                <li className="text-sm"><span className="font-semibold">User-Provided Information : </span>Users may provide personal information, including but not limited to name, email address, and profile details when using the application.</li>
                                <li className="text-sm"><span className="font-semibold">Transaction Information : </span>When users make purchases or bookings, we collect information necessary for payment processing.</li>
                                <li className="text-sm"><span className="font-semibold">Automatically Collected Information : </span>We may collect information about user interactions with the application, such as device information, IP addresses, and usage patterns.</li>
                            </ol>
                        </li>

                        <li className=" font-semibold">How We Use Information
                            <ol className="list-inside pl-0 font-normal text-base" style={{ listStyleType: 'lower-alpha' }}>
                                <li className="text-sm"><span className="font-semibold">Providing Services : </span>User-provided information is used to facilitate bookings, registrations, and purchases.</li>
                                <li className="text-sm"><span className="font-semibold">Improving User Experience : </span>Collected data helps us enhance the application's functionality, user interface, and content relevance.</li>
                                <li className="text-sm"><span className="font-semibold">Communication : </span>We may use provided contact information to send transaction-related updates, respond to inquiries, and send periodic newsletters.</li>
                            </ol>
                        </li>
                        <li className=" font-semibold">Information Sharing
                            <ol className="list-inside pl-0 font-normal text-base" style={{ listStyleType: 'lower-alpha' }}>
                                <li className="text-sm"><span className="font-semibold">Third-Party Services : </span>We may share information with third-party service providers for payment processing, analytics, and other application-related functions.</li>
                                <li className="text-sm"><span className="font-semibold">Legal Compliance : </span>We may disclose information in response to legal processes or to comply with applicable laws.</li>
                            </ol>
                        </li>
                        <li className=" font-semibold">User Control and Choices
                            <ol className="list-inside pl-0 font-normal text-base" style={{ listStyleType: 'lower-alpha' }}>
                                <li className="text-sm"><span className="font-semibold">Account Information : </span>Users can update their account information and preferences through the application.</li>
                                <li className="text-sm"><span className="font-semibold">Communication Preferences : </span>Users can opt-out of promotional communications by following the unsubscribe instructions in the emails received.</li>
                            </ol>
                        </li>
                        <li className=" font-semibold text-sm">Security
                            <p>We implement reasonable security measures to protect user information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                        </li>
                        <li className=" font-semibold text-sm">Third-Party Links
                            <p>The application may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites.</p>
                        </li>
                        <li className=" font-semibold text-sm">Children's Privacy
                            <p>The application is not intended for users under the age of 13. We do not knowingly collect personal information from children.</p>
                        </li>
                        <li className=" font-semibold text-sm">Changes to this Privacy Policy
                            <p>We may update this Privacy Policy to reflect changes in our practices. Users are encouraged to review the policy periodically.</p>
                        </li>
                        <li className=" font-semibold text-sm">Contact Us
                            <p>If you have questions or concerns about this Privacy Policy, please contact us at <a href="mailto:admin@spectransyscore.com" className="text-blue-600 underline">admin@spectransyscore.com</a>.</p>
                        </li>

                    </ol>
                    <p className="text-sm font-sans py-4"><span className="font-semibold"> Last Updated :</span> 14/02/2024</p>

                </section>
            </main>
        </>
    );
}

export default PrivacyPolicy;